.swiper-slide {
  width: auto !important;
}

.carousel {
  display: flex;
  width: 100%;
  height: 100%;
}

.carousel__list {
  display: flex;
  list-style: none;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  perspective: 300px;
}

.carousel__item {
  background-image: url("../public/img/page.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 70vh;
  max-width: 25vw !important;
  padding: 20px 50px;
  width: 100%;
  position: absolute;
  @media (max-width: 1371.98px) {
    max-width: 476px !important;
  }
  /* transition: all 0.3s ease-in; */
}

.carousel__item[data-pos="0"] {
  z-index: 5;
}

.carousel__item[data-pos="-1"],
.carousel__item[data-pos="1"] {
  opacity: 0.8;
  filter: blur(1px) grayscale(10%);
}

.carousel__item[data-pos="-1"] {
  transform: translateX(-70%) scale(0.8);
  z-index: 4;
}

.carousel__item[data-pos="1"] {
  transform: translateX(70%) scale(0.8);
  z-index: 4;
}

/* Animation */

.circle {
  width: 25vw;
  height: 25vw;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(200, 200, 200, 1) 21%,
    rgba(190, 190, 190, 1) 28%,
    rgba(175, 175, 175, 0.5) 57%,
    rgba(255, 255, 255, 0.2) 92%
  );
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  @media (max-width: 767.98px) {
    width: 45vw;
    height: 45vw;
  }
}

.circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    transparent 10%,
    rgba(0, 0, 0, 0.2) 50%,
    transparent 90%
  );
  border-radius: 50%;
  animation: rippleAnimation 2s linear infinite;
  transform: scale(0);
  opacity: 0;
}

.circle-img {
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rippleAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
